import A from "assets/images/PBP.jpg";
export default [
  {
    title: "Mecanique du point",
    description: "",
    items: [
      {
        image: A,
        name: "Centrifugeuse",
        count: 1,
        route: "/Pbs/Physique/P52",
      },
      {
        image: A,
        name: "Mission Apollo",
        count: 2,
        route: "/Pbs/Physique/P53",
      },
      {
        image: A,
        name: "Satellites d'observation terrestre",
        count: 3,
        route: "/Pbs/Physique/P54",
      },
      {
        image: A,
        name: "Secousses Tunnel",
        count: 4,
        route: "/Pbs/Physique/P55",
      },
      {
        image: A,
        name: "Deviation lumiere par etoiles",
        count: 5,
        route: "/Pbs/Physique/P56",
      },
      {
        image: A,
        name: "Champ EB ord Plan Chambre Projection Temporelle Thermique Transistor",
        count: 6,
        route: "/Pbs/Physique/P57",
      },
      {
        image: A,
        name: "Ressort et amortisseur",
        count: 7,
        route: "/Pbs/Physique/P58",
      },
      {
        image: A,
        name: "Satellite Ellipse Viriel Gravitation Pesanteur",
        count: 8,
        route: "/Pbs/Physique/P59",
      },
      {
        image: A,
        name: "CO et CO2 Circuits Couples",
        count: 9,
        route: "/Pbs/Physique/P60",
      },
      {
        image: A,
        name: "Orbitogramme Frottement",
        count: 10,
        route: "/Pbs/Physique/P61",
      },
      {
        image: A,
        name: "Modele Thomson Effet Zeeman Chrome Ellingham Binaire EauHF",
        count: 11,
        route: "/Pbs/Physique/P62",
      },
      {
        image: A,
        name: "Thermoregulation Cycliste Etoile Double Modulation",
        count: 12,
        route: "/Pbs/Physique/P63",
      },
      {
        image: A,
        name: "Champ Magnetique Terrestre",
        count: 13,
        route: "/Pbs/Physique/P64",
      },
      {
        image: A,
        name: "Point Sur Disque Voiture",
        count: 14,
        route: "/Pbs/Physique/P65",
      },
      {
        image: A,
        name: "Moteur Electrostatique Variometre Laiton",
        count: 15,
        route: "/Pbs/Physique/P66",
      },
      {
        image: A,
        name: "Ecrantage B Levitation Sphere Supraconductrice Pollution Mercure",
        count: 16,
        route: "/Pbs/Physique/P67",
      },
      {
        image: A,
        name: "Vaisseau Spatial Microscope Force Atomique",
        count: 17,
        route: "/Pbs/Physique/P68",
      },
      {
        image: A,
        name: "Vecteur Excentricite Compressions Monothermes Viseur Lunettes",
        count: 18,
        route: "/Pbs/Physique/P69",
      },
      {
        image: A,
        name: "Sphere Conductrice Dans E Uniforme Piege De Penning",
        count: 19,
        route: "/Pbs/Physique/P70",
      },
      {
        image: A,
        name: "Optique non lineaire",
        count: 20,
        route: "/Pbs/Physique/P71",
      },
      {
        image: A,
        name: "Avancement Gaz Accelerometre Temperature Lune",
        count: 21,
        route: "/Pbs/Physique/P72",
      },
      {
        image: A,
        name: "Disque Rotation Initiale Passe Bande Triangle Cavite Laser Gravitation",
        count: 22,
        route: "/Pbs/Physique/P73",
      },
      {
        image: A,
        name: "Electron dans un champ magnetique constant",
        count: 23,
        route: "/Pbs/Physique/P74",
      },
      {
        image: A,
        name: "Filtres Oscillations Non Galileen Doubleur Et Hyperfocale Cycle Entropie",
        count: 24,
        route: "/Pbs/Physique/P75",
      },
      {
        image: A,
        name: "Levitation Magnetique Moteur Lineaire Ressort",
        count: 25,
        route: "/Pbs/Physique/P76",
      },
      {
        image: A,
        name: "Moteur Entropie Interferences Miroirs Modeles Atome",
        count: 26,
        route: "/Pbs/Physique/P77",
      },
      {
        image: A,
        name: "Pendule Cinetique Densite Optique",
        count: 27,
        route: "/Pbs/Physique/P78",
      },
      {
        image: A,
        name: "Portrait Phase Elec Non Galileen Energie Liquefaction Compression Isotherme",
        count: 28,
        route: "/Pbs/Physique/P79",
      },
      {
        image: A,
        name: "Ressort et frottement",
        count: 29,
        route: "/Pbs/Physique/P80",
      },
      {
        image: A,
        name: "Ressorts et equations differentielles",
        count: 30,
        route: "/Pbs/Physique/P81",
      },
    ],
  },
  {
    title: "Optique Gemetrique",
    description: "",
    items: [
      {
        image: A,
        name: "Synthese d'ouverture",
        count: 1,
        route: "/Pbs/Physique/P141",
      },
      {
        image: A,
        name: "Fibre Optique Aluminium",
        count: 2,
        route: "/Pbs/Physique/P142",
      },
      {
        image: A,
        name: "Filtres Oscillations Non Galileen Doubleur Et Hyperfocale Cycle Entropie",
        count: 3,
        route: "/Pbs/Physique/P143",
      },
      {
        image: A,
        name: "Terebenthine Retro Antireflet",
        count: 4,
        route: "/Pbs/Physique/P144",
      },
      {
        image: A,
        name: "Miroirs et lentilles",
        count: 5,
        route: "/Pbs/Physique/P145",
      },
      {
        image: A,
        name: "Satellites d observation terrestre",
        count: 6,
        route: "/Pbs/Physique/P146",
      },
      {
        image: A,
        name: "Diffraction et resolution du reseau",
        count: 7,
        route: "/Pbs/Physique/P147",
      },
      {
        image: A,
        name: "Boucle Detection Chute Dans B Diffraction Young",
        count: 8,
        route: "/Pbs/Physique/P148",
      },
      {
        image: A,
        name: "Reseau Reflexion Defibrillateur Chlore",
        count: 9,
        route: "/Pbs/Physique/P149",
      },
      {
        image: A,
        name: "Filtrage Optique Chlore En Solution Detente Eau Diazote",
        count: 10,
        route: "/Pbs/Physique/P150",
      },
      {
        image: A,
        name: "Spectrophotometre",
        count: 11,
        route: "/Pbs/Physique/P151",
      },
      {
        image: A,
        name: "Projecteur Observation Etoiles",
        count: 12,
        route: "/Pbs/Physique/P152",
      },
      {
        image: A,
        name: "Reseau a echelettes",
        count: 13,
        route: "/Pbs/Physique/P153",
      },
      {
        image: A,
        name: "Michelson Gravitation Interferences Dispersion Mg Corrosion Binaire HNO3",
        count: 14,
        route: "/Pbs/Physique/P154",
      },
      {
        image: A,
        name: "Chauffage Plaque Foucault Thermometre Cristaux Liquides",
        count: 15,
        route: "/Pbs/Physique/P155",
      },
      {
        image: A,
        name: "Effet Moyenne Couleurs Diffraction",
        count: 16,
        route: "/Pbs/Physique/P156",
      },
      {
        image: A,
        name: "Diffraction et effets turbulence pour telescope",
        count: 17,
        route: "/Pbs/Physique/P157",
      },
      {
        image: A,
        name: "Diffraction de base",
        count: 18,
        route: "/Pbs/Physique/P158",
      },
      {
        image: A,
        name: "Telepherique Holographie",
        count: 19,
        route: "/Pbs/Physique/P159",
      },
      {
        image: A,
        name: "Vecteur Excentricite Compressions Monothermes Viseur Lunettes",
        count: 20,
        route: "/Pbs/Physique/P160",
      },
    ],
  },
  {
    title: "Thermodynamique",
    description: "",
    items: [
      {
        image: A,
        name: "Portrait Phase Elec Non Galileen Energie Liquefaction Compression Isotherme",
        count: 1,
        route: "/Pbs/Physique/P180",
      },
      {
        image: A,
        name: "Plasma Guide Ondes Cycle Trois Transformations",
        count: 2,
        route: "/Pbs/Physique/P181",
      },
      {
        image: A,
        name: "Filtres Oscillations Non Galileen Doubleur Et Hyperfocale Cycle Entropie",
        count: 3,
        route: "/Pbs/Physique/P182",
      },
      {
        image: A,
        name: "Mach Zehnder Filtrage Refrigeration Air Pulse",
        count: 4,
        route: "/Pbs/Physique/P183",
      },
      {
        image: A,
        name: "Cycle Diesel",
        count: 5,
        route: "/Pbs/Physique/P184",
      },
      {
        image: A,
        name: "Chauffage local en fonction de t",
        count: 6,
        route: "/Pbs/Physique/P185",
      },
      {
        image: A,
        name: "Rendement Beau De Rochas Alternateur Oxydes Cuivre",
        count: 7,
        route: "/Pbs/Physique/P186",
      },
      {
        image: A,
        name: "Rail Laplace Moteur Curzon",
        count: 8,
        route: "/Pbs/Physique/P187",
      },
      {
        image: A,
        name: "Chaine Production Azote",
        count: 9,
        route: "/Pbs/Physique/P188",
      },
      {
        image: A,
        name: "Turbine Vapeur Spectrometrie Interferentielle",
        count: 10,
        route: "/Pbs/Physique/P189",
      },
      {
        image: A,
        name: "Gaz parfait et eau liquide et vapeur",
        count: 11,
        route: "/Pbs/Physique/P190",
      },
      {
        image: A,
        name: "Vecteur Excentricite Compressions Monothermes Viseur Lunettes",
        count: 12,
        route: "/Pbs/Physique/P191",
      },
      {
        image: A,
        name: "Moteur Entropie Interferences Miroirs Modeles Atome",
        count: 13,
        route: "/Pbs/Physique/P192",
      },
      {
        image: A,
        name: "Filtrage Optique Chlore En Solution Detente Eau Diazote",
        count: 14,
        route: "/Pbs/Physique/P193",
      },
      {
        image: A,
        name: "Compresseur Adiabatique Elihu Thomson",
        count: 15,
        route: "/Pbs/Physique/P194",
      },
      {
        image: A,
        name: "Reacteur Brayton Laser Effet Thermique",
        count: 16,
        route: "/Pbs/Physique/P195",
      },
    ],
  },
  {
    title: "ARQS",
    description: "",
    items: [
      {
        image: A,
        name: "Onde Reflexion Dielectrique Solenoide",
        count: 1,
        route: "/Pbs/Physique/P1",
      },
      {
        image: A,
        name: "Champs E et B dans un condensateur",
        count: 2,
        route: "/Pbs/Physique/P2",
      },
    ],
  },
  {
    title: "Electrostatique",
    description: "",
    items: [
      {
        image: A,
        name: "Conducteurs cylindriques en electrostatique",
        count: 1,
        route: "/Pbs/Physique/P21",
      },
      {
        image: A,
        name: "Moteur Entropie Interferences Miroirs Modeles Atome",
        count: 2,
        route: "/Pbs/Physique/P22",
      },
      {
        image: A,
        name: "Onde Reflexion Dielectrique Solenoide",
        count: 3,
        route: "/Pbs/Physique/P23",
      },
      {
        image: A,
        name: "Charges ponctuelles en electrostatique",
        count: 4,
        route: "/Pbs/Physique/P24",
      },
      {
        image: A,
        name: "Avancement Gaz Accelerometre Temperature Lune",
        count: 5,
        route: "/Pbs/Physique/P25",
      },
      {
        image: A,
        name: "La foudre",
        count: 6,
        route: "/Pbs/Physique/P26",
      },
      {
        image: A,
        name: "Sphere Conductrice Dans E Uniforme Piege De Penning",
        count: 7,
        route: "/Pbs/Physique/P27",
      },
      {
        image: A,
        name: "Modele Thomson Effet Zeeman Chrome Ellingham Binaire Eau HF",
        count: 8,
        route: "/Pbs/Physique/P28",
      },
      {
        image: A,
        name: "Moteur Electrostatique Variometre Laiton",
        count: 9,
        route: "/Pbs/Physique/P29",
      },
      {
        image: A,
        name: "Fibre optique saut et gradient",
        count: 10,
        route: "/Pbs/Physique/P30",
      },
      {
        image: A,
        name: "Champ EB ord Plan Chambre Projection Temporelle Thermique Transistor",
        count: 11,
        route: "/Pbs/Physique/P31",
      },
      {
        image: A,
        name: "Biprisme Effet Hall Pression Electromagnetisme",
        count: 12,
        route: "/Pbs/Physique/P32",
      },
    ],
  },
  {
    title: "Magnetostatique",
    description: "",
    items: [
      {
        image: A,
        name: "Biprisme Effet Hall Pression Electromagnetisme",
        count: 1,
        route: "/Pbs/Physique/P44",
      },
      {
        image: A,
        name: "Champ Magnetique Terrestre",
        count: 2,
        route: "/Pbs/Physique/P45",
      },
      {
        image: A,
        name: "Compresseur Adiabatique Elihu Thomson",
        count: 3,
        route: "/Pbs/Physique/P46",
      },
      {
        image: A,
        name: "EcrantageB LevitationSphereSupraconductrice PollutionMercure",
        count: 4,
        route: "/Pbs/Physique/P47",
      },
      {
        image: A,
        name: "Effet Hall et magnetoresistance",
        count: 5,
        route: "/Pbs/Physique/P48",
      },
      {
        image: A,
        name: "Indice Distillation.pdf",
        count: 6,
        route: "/Pbs/Physique/P49",
      },
      {
        image: A,
        name: "LevitationMagnetique MoteurLineaire Ressort",
        count: 7,
        route: "/Pbs/Physique/P50",
      },
      {
        image: A,
        name: "Sphere supraconductrice",
        count: 8,
        route: "/Pbs/Physique/P51",
      },
    ],
  },
];
