import Al1 from "assets/images/Formation/Ilham/Al1.png";
import Al2 from "assets/images/Formation/Ilham/Al2.png";
import Al3 from "assets/images/Formation/Ilham/Al3.png";
import Al4 from "assets/images/Formation/Ilham/Al4.png";
import Al5 from "assets/images/Formation/Ilham/Al5.png";
import Al6 from "assets/images/Formation/Ilham/Al6.png";
import Al7 from "assets/images/Formation/Ilham/Al7.png";
import Ag1 from "assets/images/Formation/Ilham/Ag1.png";
import Ag2 from "assets/images/Formation/Ilham/Ag2.png";
import Ag3 from "assets/images/Formation/Ilham/Ag3.png";
import R1 from "assets/images/Formation/Ilham/R1.png";
import R2 from "assets/images/Formation/Ilham/R2.png";
import R3 from "assets/images/Formation/Ilham/R3.png";
import R4 from "assets/images/Formation/Ilham/R4.png";
import R5 from "assets/images/Formation/Ilham/R5.png";
import EP1 from "assets/images/Formation/Ilham/EP1.png";
import EP2 from "assets/images/Formation/Ilham/EP2.png";
export default [
  {
    title: "Solution aqueuse",
    description: "",
    items: [
      {
        image: Al1,
        name: "Chlore En Solution Detente Eau Diazote",
        count: 1,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: Al2,
        name: "Spectrophotometre",
        count: 2,
        route: "/sections/page-sections/features",
      },
      {
        image: Al3,
        name: "Guide Avalanche Cadmium",
        count: 3,
      },
      {
        image: Al4,
        name: "Bifurcation Mecanique Chauffe Eau Solaire Depot Laiton",
        count: 4,
      },
      {
        image: Al5,
        name: "Silicium",
        count: 5,
      },
      {
        image: Al6,
        name: "Chrome",
        count: 6,
      },
      {
        image: Al7,
        name: "Argent",
        count: 7,
      },
      {
        image: Al1,
        name: "Micro Ondes Sulfite Sulfate",
        count: 8,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: Al2,
        name: "Solubilite Aluminium Or Diagramme EpCN",
        count: 9,
        route: "/sections/page-sections/features",
      },
      {
        image: Al3,
        name: "MoteurElectrostatique Variometre Laiton",
        count: 10,
      },
      {
        image: Al4,
        name: "Mercure",
        count: 11,
      },
    ],
  },
  {
    title: "Cinetique Chimie",
    description: "",
    items: [
      {
        image: Ag1,
        name: "Mercure",
        count: 1,
      },
      {
        image: Ag2,
        name: "Chrome",
        count: 2,
      },
      {
        image: Ag3,
        name: "Pendule CinetiqueDensiteOptique",
        count: 3,
      },
      {
        image: Ag3,
        name: "Filtrage Optique Chlore En Solution Detente Eau Diazote",
        count: 4,
      },
      {
        image: Ag1,
        name: "Soufre",
        count: 5,
      },
      {
        image: Ag2,
        name: "Silicium",
        count: 6,
      },
      {
        image: Ag3,
        name: "FibreOptique Aluminium",
        count: 7,
      },
    ],
  },
  {
    title: "Atomistique",
    description: "",
    items: [
      {
        image: R1,
        name: "Titane",
        count: 1,
      },
      {
        image: R2,
        name: "Silicium",
        count: 2,
      },
      {
        image: R3,
        name: "Chrome",
        count: 3,
      },
      {
        image: R4,
        name: "Soufre",
        count: 4,
      },
      {
        image: R5,
        name: "Mercure",
        count: 5,
      },
      {
        image: R5,
        name: "Argent",
        count: 6,
      },
      {
        image: R1,
        name: "Brome",
        count: 7,
      },
    ],
  },
  {
    title: "Structure",
    description: "",
    items: [
      {
        image: EP1,
        name: "CO et CO2",
        count: 1,
      },
      {
        image: EP2,
        name: "Chlore En Solution Detente Eau Diazote",
        count: 2,
      },
      {
        image: EP1,
        name: "Titane",
        count: 3,
      },
      {
        image: EP2,
        name: "Chrome",
        count: 4,
      },
      {
        image: EP2,
        name: "Soufre",
        count: 5,
      },
    ],
  },
  {
    title: "diagramme E-pH",
    description: "",
    items: [
      {
        image: R1,
        name: "Argent",
        count: 1,
      },
      {
        image: R2,
        name: "FibreOptique Aluminium",
        count: 2,
      },
      {
        image: R3,
        name: "Guide Avalanche Cadmium",
        count: 3,
      },
      {
        image: R4,
        name: "BifurcationMecanique ChauffeEauSolaire DepotLaiton",
        count: 4,
      },
      {
        image: R5,
        name: "MichelsonGravitation InterferencesDispersion MgCorrosion BinaireHNO3",
        count: 5,
      },
      {
        image: R5,
        name: "GuideTM RayonnementReception EpHChrome",
        count: 6,
      },
      {
        image: R1,
        name: "Coaxial GuideRectangulaire Doppler PotentielPhChrome",
        count: 7,
      },
      {
        image: R2,
        name: "ReseauReflexion Defibrillateur Chlore",
        count: 8,
      },
      {
        image: R3,
        name: "SolubiliteAluminium OrDiagrammeEpCN",
        count: 9,
      },
      {
        image: R4,
        name: "Mercure",
        count: 10,
      },
      {
        image: R5,
        name: "EcrantageB LevitationSphereSupraconductrice PollutionMercure",
        count: 11,
      },
      {
        image: R5,
        name: "Brome",
        count: 12,
      },
    ],
  },
  {
    title: "Diagramme binaire",
    description: "",
    items: [
      {
        image: R1,
        name: "BinaireEauHF",
        count: 1,
      },
      {
        image: R2,
        name: "Oxydes Cuivre",
        count: 2,
      },
      {
        image: R3,
        name: "Skeleton OndeMetal PreparationTitane",
        count: 3,
      },
      {
        image: R4,
        name: "Indice Distillation",
        count: 4,
      },
    ],
  },
  {
    title: "Cristallographie",
    description: "",
    items: [
      {
        image: R1,
        name: "Terebenthine Retro Antireflet",
        count: 1,
      },
      {
        image: R2,
        name: "MichelsonGravitation InterferencesDispersion MgCorrosion BinaireHNO3",
        count: 2,
      },
      {
        image: R3,
        name: "ModeleThomsonEffetZeeman ChromeEllingham BinaireEauHF",
        count: 3,
      },
      {
        image: R4,
        name: "Jouets BinaireEauEthanol ModulationPhase",
        count: 4,
      },
      {
        image: R1,
        name: "Titane",
        count: 5,
      },
      {
        image: R2,
        name: "Silicium",
        count: 6,
      },
      {
        image: R3,
        name: "Chrome",
        count: 7,
      },
      {
        image: R4,
        name: "Argent",
        count: 8,
      },
      {
        image: R4,
        name: "Brome",
        count: 9,
      },
    ],
  },
];
