import Sup from "assets/images/Sup1.jpg";
import Spe from "assets/images/Spe2.jpg";
export default [
  {
    image: Sup,
    name: "Sup",
    route: "/Pbs/Sup/Chimie",
  },
  {
    image: Spe,
    name: "Spe",
    route: "/Pbs/Spe/Chimie",
  },
];
