import A from "assets/images/pbp.png";
export default [
  {
    title: "Mecanique Solide",
    description: "",
    items: [
      {
        image: A,
        name: "Velo diode roue libre",
        count: 1,
        route: "/Pbs/Physique/P82",
      },
      {
        image: A,
        name: "Roue Freinage Roue Tapis Roulant Timochenko",
        count: 2,
        route: "/Pbs/Physique/P83",
      },
      {
        image: A,
        name: "Jouets Binaire Eau Ethanol Modulation Phase",
        count: 3,
        route: "/Pbs/Physique/P84",
      },
      {
        image: A,
        name: "Roues de bicyclette",
        count: 4,
        route: "/Pbs/Physique/P85",
      },
      {
        image: A,
        name: "Terebenthine Retro Antireflet",
        count: 5,
        route: "/Pbs/Physique/P86",
      },
      {
        image: A,
        name: "Chute Tartine Reduction NiO Diffusion Barre",
        count: 6,
        route: "/Pbs/Physique/P87",
      },
      {
        image: A,
        name: "Secousses Tunnel",
        count: 7,
        route: "/Pbs/Physique/P88",
      },
      {
        image: A,
        name: "Bifurcation Mecanique Chauffe Eau Solaire Depot Laiton",
        count: 8,
        route: "/Pbs/Physique/P89",
      },
      {
        image: A,
        name: "Point Sur Disque Voiture",
        count: 9,
        route: "/Pbs/Physique/P90",
      },
      {
        image: A,
        name: "Tension Rotation Stick Slip Condensateur HF",
        count: 10,
        route: "/Pbs/Physique/P91",
      },
      {
        image: A,
        name: "Telepherique Holographie",
        count: 11,
        route: "/Pbs/Physique/P92",
      },
      {
        image: A,
        name: "Oscillations",
        count: 12,
        route: "/Pbs/Physique/P93",
      },
      {
        image: A,
        name: "Scooter",
        count: 13,
        route: "/Pbs/Physique/P94",
      },
      {
        image: A,
        name: "Wagonnet sur une pente",
        count: 14,
        route: "/Pbs/Physique/P95",
      },
      {
        image: A,
        name: "Convoyeur Rouleaux Monochromateur Vaporeformage",
        count: 15,
        route: "/Pbs/Physique/P96",
      },
      {
        image: A,
        name: "Disque Rotation Initiale Passe Bande Triangle Cavite Laser Gravitation",
        count: 16,
        route: "/Pbs/Physique/P97",
      },
      {
        image: A,
        name: "Roue sur un profil cylindrique",
        count: 17,
        route: "/Pbs/Physique/P98",
      },
    ],
  },
  {
    title: "Electronique",
    description: "",
    items: [
      {
        image: A,
        name: "Velo diode roue libre",
        count: 1,
        route: "/Pbs/Physique/P3",
      },
      {
        image: A,
        name: "Filtres Oscillations Non Galileen Doubleur Et Hyperfocale Cycle Entropie",
        count: 2,
        route: "/Pbs/Physique/P4",
      },
      {
        image: A,
        name: "Portrait Phase Elec Non Galileen Energie Liquefaction Compression Isotherme",
        count: 3,
        route: "/Pbs/Physique/P5",
      },
      {
        image: A,
        name: "Electrocinetique de base",
        count: 4,
        route: "/Pbs/Physique/P6",
      },
      {
        image: A,
        name: "Disque Rotation Initiale Passe Bande Triangle Cavite Laser Gravitation",
        count: 5,
        route: "/Pbs/Physique/P7",
      },
      {
        image: A,
        name: "CO et CO2 Circuits Couples",
        count: 6,
        route: "/Pbs/Physique/P8",
      },
      {
        image: A,
        name: "Mach Zehnder Filtrage Refrigeration AirPulse",
        count: 7,
        route: "/Pbs/Physique/P9",
      },
      {
        image: A,
        name: "Thermoregulation Cycliste Etoile Double Modulation",
        count: 8,
        route: "/Pbs/Physique/P10",
      },
      {
        image: A,
        name: "Reseau Reflexion Defibrillateur Chlore",
        count: 9,
        route: "/Pbs/Physique/P11",
      },
      {
        image: A,
        name: "Filtre selectif",
        count: 10,
        route: "/Pbs/Physique/P12",
      },
      {
        image: A,
        name: "Moteur Electrostatique Variometre Laiton",
        count: 11,
        route: "/Pbs/Physique/P13",
      },
      {
        image: A,
        name: "Modele Thomson Effet Zeeman Chrome Ellingham Binaire Eau HF",
        count: 12,
        route: "/Pbs/Physique/P14",
      },
      {
        image: A,
        name: "Michelson Gravitation Interferences Dispersion Mg Corrosion Binaire HNO3",
        count: 13,
        route: "/Pbs/Physique/P15",
      },
      {
        image: A,
        name: "Detection Synchrone Filtre Universel",
        count: 14,
        route: "/Pbs/Physique/P16",
      },
      {
        image: A,
        name: "Onde thermique",
        count: 15,
        route: "/Pbs/Physique/P17",
      },
      {
        image: A,
        name: "Effet Moyenne Couleurs Diffraction",
        count: 16,
        route: "/Pbs/Physique/P18",
      },
      {
        image: A,
        name: "Filtre Modulation Demodulation",
        count: 17,
        route: "/Pbs/Physique/P19",
      },
      {
        image: A,
        name: "Jouets Binaire Eau Ethanol Modulation Phase",
        count: 18,
        route: "/Pbs/Physique/P20",
      },
    ],
  },
  {
    title: "Induction",
    description: "",
    items: [
      {
        image: A,
        name: "Champ Magnetique Terrestre",
        count: 1,
        route: "/Pbs/Physique/P33",
      },
      {
        image: A,
        name: "Temperature Terre Rail Laplace Michelson Planeite",
        count: 2,
        route: "/Pbs/Physique/P34",
      },
      {
        image: A,
        name: "Chute aimant dans tube metallique",
        count: 3,
        route: "/Pbs/Physique/P35",
      },
      {
        image: A,
        name: "Levitation Magnetique Moteur Lineaire Ressort",
        count: 4,
        route: "/Pbs/Physique/P36",
      },
      {
        image: A,
        name: "Rendement Beau De Rochas Alternateur Oxydes Cuivre",
        count: 5,
        route: "/Pbs/Physique/P37",
      },
      {
        image: A,
        name: "Rail Laplace Moteur Curzon",
        count: 6,
        route: "/Pbs/Physique/P38",
      },
      {
        image: A,
        name: "Ecrantage B Levitation Sphere Supraconductrice Pollution Mercure",
        count: 7,
        route: "/Pbs/Physique/P39",
      },
      {
        image: A,
        name: "Spire dans un champ magnetique uniforme",
        count: 8,
        route: "/Pbs/Physique/P40",
      },
      {
        image: A,
        name: "Compresseur Adiabatique Elihu Thomson",
        count: 9,
        route: "/Pbs/Physique/P41",
      },
      {
        image: A,
        name: "Skeleton Onde Metal Preparation Titane",
        count: 10,
        route: "/Pbs/Physique/P42",
      },
      {
        image: A,
        name: "Boucle Detection Chute Dans B Diffraction Young",
        count: 11,
        route: "/Pbs/Physique/P43",
      },
    ],
  },
  {
    title: "Optique Ondulatoire",
    description: "",
    items: [
      {
        image: A,
        name: "Optique non lineaire",
        count: 1,
        route: "/Pbs/Physique/P123",
      },
      {
        image: A,
        name: "Synthese d'ouverture",
        count: 2,
        route: "/Pbs/Physique/P124",
      },
      {
        image: A,
        name: "Convoyeur Rouleaux Monochromateur Vaporeformage",
        count: 3,
        route: "/Pbs/Physique/P125",
      },
      {
        image: A,
        name: "Reseau Reflexion Defibrillateur Chlore",
        count: 4,
        route: "/Pbs/Physique/P126",
      },
      {
        image: A,
        name: "Biprisme Effet Hall Pression Electromagnetisme",
        count: 5,
        route: "/Pbs/Physique/P127",
      },
      {
        image: A,
        name: "Turbine Vapeur Spectrometrie Interferentielle",
        count: 6,
        route: "/Pbs/Physique/P128",
      },
      {
        image: A,
        name: "Temperature Terre Rail Laplace Michelson Planeite",
        count: 7,
        route: "/Pbs/Physique/P129",
      },
      {
        image: A,
        name: "Mach Zehnder Filtrage Refrigeration AirPulse",
        count: 8,
        route: "/Pbs/Physique/P130",
      },
      {
        image: A,
        name: "Spectrophotometre",
        count: 9,
        route: "/Pbs/Physique/P131",
      },
      {
        image: A,
        name: "Refractometrie par interferometrie",
        count: 10,
        route: "/Pbs/Physique/P132",
      },
      {
        image: A,
        name: "Refractometrie avec un Mach Zehnder",
        count: 11,
        route: "/Pbs/Physique/P133",
      },
      {
        image: A,
        name: "Projecteur Observation Etoiles",
        count: 12,
        route: "/Pbs/Physique/P134",
      },
      {
        image: A,
        name: "Terebenthine Retro Antireflet",
        count: 13,
        route: "/Pbs/Physique/P135",
      },
      {
        image: A,
        name: "Michelson Gravitation Interferences Dispersion Mg Corrosion Binaire HNO3",
        count: 14,
        route: "/Pbs/Physique/P136",
      },
      {
        image: A,
        name: "Chauffage Plaque Foucault Thermometre Cristaux Liquides",
        count: 15,
        route: "/Pbs/Physique/P137",
      },
      {
        image: A,
        name: "Lambdametre",
        count: 16,
        route: "/Pbs/Physique/P138",
      },
      {
        image: A,
        name: "Telepherique Holographie",
        count: 17,
        route: "/Pbs/Physique/P139",
      },
      {
        image: A,
        name: "Moteur Entropie Interferences Miroirs Modeles Atome",
        count: 18,
        route: "/Pbs/Physique/P140",
      },
    ],
  },
  {
    title: "Thermique",
    description: "",
    items: [
      {
        image: A,
        name: "Thermique en regime stationnaire avec cylindre",
        count: 1,
        route: "/Pbs/Physique/P161",
      },
      {
        image: A,
        name: "Bifurcation Mecanique Chauffe Eau Solaire Depot Laiton",
        count: 2,
        route: "/Pbs/Physique/P162",
      },
      {
        image: A,
        name: "Temperature Terre Rail Laplace Michelson Planeite",
        count: 3,
        route: "/Pbs/Physique/P163",
      },
      {
        image: A,
        name: "Lampe a incandescence",
        count: 4,
        route: "/Pbs/Physique/P164",
      },
      {
        image: A,
        name: "Cartes Electroniques",
        count: 5,
        route: "/Pbs/Physique/P165",
      },
      {
        image: A,
        name: "Chute Tartine Reduction NiO Diffusion Barre",
        count: 6,
        route: "/Pbs/Physique/P166",
      },
      {
        image: A,
        name: "Formation couche de glace sur lac",
        count: 7,
        route: "/Pbs/Physique/P167",
      },
      {
        image: A,
        name: "Avancement Gaz Accelerometre Temperature Lune",
        count: 8,
        route: "/Pbs/Physique/P168",
      },
      {
        image: A,
        name: "Micro Ondes Sulfite Sulfate",
        count: 9,
        route: "/Pbs/Physique/P169",
      },
      {
        image: A,
        name: "Isolation thermique d un tube vaporisateur",
        count: 10,
        route: "/Pbs/Physique/P170",
      },
      {
        image: A,
        name: "Champ EB ord Plan Chambre Projection Temporelle Thermique Transistor",
        count: 11,
        route: "/Pbs/Physique/P171",
      },
      {
        image: A,
        name: "Thermoregulation Cycliste Etoile Double Modulation",
        count: 12,
        route: "/Pbs/Physique/P172",
      },
      {
        image: A,
        name: "Cryostat Regime Non Stationnaire",
        count: 13,
        route: "/Pbs/Physique/P173",
      },
      {
        image: A,
        name: "Thermique appliquee au corps humain",
        count: 14,
        route: "/Pbs/Physique/P174",
      },
      {
        image: A,
        name: "Experience Fourier Effet Peau",
        count: 15,
        route: "/Pbs/Physique/P175",
      },
      {
        image: A,
        name: "Chauffage Plaque Foucault Thermometre Cristaux Liquides",
        count: 16,
        route: "/Pbs/Physique/P176",
      },
      {
        image: A,
        name: "Onde thermique",
        count: 17,
        route: "/Pbs/Physique/P177",
      },
      {
        image: A,
        name: "Thermique dans un fil electrique",
        count: 18,
        route: "/Pbs/Physique/P178",
      },
      {
        image: A,
        name: "Reacteur Brayton Laser Effet Thermique",
        count: 19,
        route: "/Pbs/Physique/P179",
      },
    ],
  },
  {
    title: "Ondes",
    description: "",
    items: [
      {
        image: A,
        name: "Ondes sur une corde",
        count: 1,
        route: "/Pbs/Physique/P99",
      },
      {
        image: A,
        name: "Disque Rotation Initiale Passe Bande Triangle CaviteLaser Gravitation",
        count: 2,
        route: "/Pbs/Physique/P100",
      },
      {
        image: A,
        name: "Onde Reflexion Dielectrique Solenoide",
        count: 3,
        route: "/Pbs/Physique/P101",
      },
      {
        image: A,
        name: "Fibre Optique Aluminium",
        count: 4,
        route: "/Pbs/Physique/P102",
      },
      {
        image: A,
        name: "Effet Faraday dans un plasma",
        count: 5,
        route: "/Pbs/Physique/P103",
      },
      {
        image: A,
        name: "Coefficients de reflexion et de transmission",
        count: 6,
        route: "/Pbs/Physique/P104",
      },
      {
        image: A,
        name: "Biprisme EffetHall Pression Electromagnetisme",
        count: 7,
        route: "/Pbs/Physique/P105",
      },
      {
        image: A,
        name: "Ligne a rubans impedance caracteristique",
        count: 8,
        route: "/Pbs/Physique/P106",
      },
      {
        image: A,
        name: "Tension Rotation Stick Slip Condensateur HF",
        count: 9,
        route: "/Pbs/Physique/P107",
      },
      {
        image: A,
        name: "Bon conducteur et guide TE",
        count: 10,
        route: "/Pbs/Physique/P108",
      },
      {
        image: A,
        name: "Indice Distillation",
        count: 11,
        route: "/Pbs/Physique/P109",
      },
      {
        image: A,
        name: "Plasma Guide Ondes Cycle Trois Transformations",
        count: 12,
        route: "/Pbs/Physique/P110",
      },
      {
        image: A,
        name: "Absorption OPPM par un gaz",
        count: 13,
        route: "/Pbs/Physique/P111",
      },
      {
        image: A,
        name: "Terebenthine Retro Antireflet",
        count: 14,
        route: "/Pbs/Physique/P112",
      },
      {
        image: A,
        name: "Modele Thomson Effet Zeeman Chrome Ellingham Binaire Eau HF",
        count: 15,
        route: "/Pbs/Physique/P113",
      },
      {
        image: A,
        name: "Guide Avalanche Cadmium",
        count: 16,
        route: "/Pbs/Physique/P114",
      },
      {
        image: A,
        name: "Coaxial Guide Rectangulaire Doppler Potentiel Ph Chrome",
        count: 17,
        route: "/Pbs/Physique/P115",
      },
      {
        image: A,
        name: "Chauffage Plaque Foucault Thermometre Cristaux Liquides",
        count: 18,
        route: "/Pbs/Physique/P116",
      },
      {
        image: A,
        name: "Experience Fourier Effet Peau",
        count: 19,
        route: "/Pbs/Physique/P117",
      },
      {
        image: A,
        name: "Metal non parfait",
        count: 20,
        route: "/Pbs/Physique/P118",
      },
      {
        image: A,
        name: "Onde quasimonochr Resonateur Guide Plasma",
        count: 21,
        route: "/Pbs/Physique/P119",
      },
      {
        image: A,
        name: "Guide TM Rayonnement Reception EpHChrome",
        count: 22,
        route: "/Pbs/Physique/P120",
      },
      {
        image: A,
        name: "Skeleton Onde Metal Preparation Titane",
        count: 23,
        route: "/Pbs/Physique/P121",
      },
      {
        image: A,
        name: "Flux et circulations pour un coaxial",
        count: 24,
        route: "/Pbs/Physique/P122",
      },
    ],
  },
];
