import B1 from "assets/images/book/1.png";
import B2 from "assets/images/book/2.png";
import B3 from "assets/images/book/3.png";
import B4 from "assets/images/book/4.png";
import B5 from "assets/images/book/5.png";
import B6 from "assets/images/book/6.png";
import B7 from "assets/images/book/7.png";
import B8 from "assets/images/book/8.png";
import B9 from "assets/images/book/9.png";
import B10 from "assets/images/book/10.png";
import B11 from "assets/images/book/11.png";
import B12 from "assets/images/book/12.png";
import B13 from "assets/images/book/13.png";
import B14 from "assets/images/book/14.png";
import B15 from "assets/images/book/15.png";
import B16 from "assets/images/book/16.png";
import B17 from "assets/images/book/17.png";
import B18 from "assets/images/book/18.png";
import B19 from "assets/images/book/19.png";
import B20 from "assets/images/book/20.png";
import B21 from "assets/images/book/21.png";
import B22 from "assets/images/book/22.png";
import B23 from "assets/images/book/23.png";
import B24 from "assets/images/book/24.png";
import B25 from "assets/images/book/25.png";
import B26 from "assets/images/book/26.png";
import B27 from "assets/images/book/27.png";
import B28 from "assets/images/book/28.png";
import B29 from "assets/images/book/29.png";
import B30 from "assets/images/book/30.png";
import B31 from "assets/images/book/31.png";
import B32 from "assets/images/book/32.png";
import B33 from "assets/images/book/33.png";
import B34 from "assets/images/book/34.png";
import B35 from "assets/images/book/35.png";
import B36 from "assets/images/book/36.png";
import B37 from "assets/images/book/37.png";
import B38 from "assets/images/book/38.png";
import B39 from "assets/images/book/39.png";
import B40 from "assets/images/book/40.png";
import B41 from "assets/images/book/41.png";
import B42 from "assets/images/book/42.png";
import B43 from "assets/images/book/43.png";
import B44 from "assets/images/book/44.png";
import B45 from "assets/images/book/45.png";
import B46 from "assets/images/book/46.png";
import B47 from "assets/images/book/47.png";
import B48 from "assets/images/book/48.png";
import B49 from "assets/images/book/49.png";
import B50 from "assets/images/book/50.png";
import B53 from "assets/images/book/53.png";
import B54 from "assets/images/book/54.png";
import B55 from "assets/images/book/55.png";
import B56 from "assets/images/book/56.png";
import B57 from "assets/images/book/57.png";
import B58 from "assets/images/book/58.png";
import B59 from "assets/images/book/59.png";
import B60 from "assets/images/book/60.png";
export default [
  {
    title: "Dubarry",
    description: "Exercices et problèmes corrigés sup",
    items: [
      {
        image: B1,
        name: "Mecanique",
        count: 1,
        route: "/livres/B1",
      },
      {
        image: B2,
        name: "Électricité",
        count: 2,
        route: "/livres/B2",
      },
      {
        image: B3,
        name: "Thermodynamique",
        count: 3,
        route: "/livres/B3",
      },
    ],
  },
  {
    title: "Nathan",
    description: "Sup/Spe",
    items: [
      {
        image: B4,
        name: "Chimie MPSI",
        count: 1,
        route: "/livres/B4",
      },
      {
        image: B5,
        name: "Chimie MP",
        count: 2,
        route: "/livres/B5",
      },
      {
        image: B6,
        name: "Electronique",
        count: 3,
        route: "/livres/B6",
      },
      {
        image: B7,
        name: "Electromagnetisme",
        count: 4,
        route: "/livres/B7",
      },
      {
        image: B8,
        name: "Electromagnetisme",
        count: 5,
        route: "/livres/B8",
      },
      {
        image: B9,
        name: "Mecanique du pt",
        count: 6,
        route: "/livres/B9",
      },
      {
        image: B10,
        name: "Mecanique solide",
        count: 7,
        route: "/livres/B10",
      },
      {
        image: B11,
        name: "Ondes",
        count: 8,
        route: "/livres/B11",
      },
      {
        image: B12,
        name: "Optique Geometrique",
        count: 9,
        route: "/livres/B12",
      },
      {
        image: B13,
        name: "Optique Ondulatoire",
        count: 10,
        route: "/livres/B13",
      },
      {
        image: B14,
        name: "Thermodynamique",
        count: 11,
        route: "/livres/B14",
      },
    ],
  },
  {
    title: "Précis",
    description: "Sup/Spe",
    items: [
      {
        image: B15,
        name: "Chimie MPSI",
        count: 1,
        route: "/livres/B15",
      },
      {
        image: B16,
        name: "Chimie MP",
        count: 2,
        route: "/livres/B16",
      },
      {
        image: B17,
        name: "Electronique MPSI",
        count: 3,
        route: "/livres/B17",
      },
      {
        image: B18,
        name: "Electromagnetisme",
        count: 4,
        route: "/livres/B18",
      },
      {
        image: B19,
        name: "Electromagnetisme",
        count: 5,
        route: "/livres/B19",
      },
      {
        image: B20,
        name: "Mecanique du pt",
        count: 6,
        route: "/livres/B20",
      },
      {
        image: B21,
        name: "Mecanique solide",
        count: 7,
        route: "/livres/B21",
      },
      {
        image: B22,
        name: "Optique Ondulatoire",
        count: 8,
        route: "/livres/B22",
      },
      {
        image: B24,
        name: "Physique Exo MP",
        count: 9,
        route: "/livres/B24",
      },
      {
        image: B23,
        name: "Physique Exo MPSI",
        count: 10,
        route: "/livres/B23",
      },
      {
        image: B25,
        name: "Thermodynamique",
        count: 11,
        route: "/livres/B25",
      },
    ],
  },
  {
    title: "1001Q",
    description: "Sup/Spe",
    items: [
      {
        image: B26,
        name: "Physique SPE",
        count: 1,
        route: "/livres/B26",
      },
      {
        image: B27,
        name: "Physique SUP",
        count: 2,
        route: "/livres/B27",
      },
    ],
  },
  {
    title: "Es-sbai",
    description: "Sup/Spe",
    items: [
      {
        image: B28,
        name: "Physique Part 1",
        count: 1,
        route: "/livres/B28",
      },
      {
        image: B29,
        name: "Physique Part 2",
        count: 2,
        route: "/livres/B29",
      },
    ],
  },
  {
    title: "H-Prepa",
    description: "Sup/Spe",
    items: [
      {
        image: B31,
        name: "Chimie MPSI",
        count: 1,
        route: "/livres/B30",
      },
      {
        image: B30,
        name: "Chimie MP",
        count: 2,
        route: "/livres/B31",
      },
      {
        image: B32,
        name: "Electromagnetisme MPSI",
        count: 3,
        route: "/livres/B32",
      },
      {
        image: B33,
        name: "Electromagnetisme",
        count: 4,
        route: "/livres/B33",
      },
      {
        image: B34,
        name: "Electronique",
        count: 5,
        route: "/livres/B34",
      },
      {
        image: B35,
        name: "Exo Physique",
        count: 6,
        route: "/livres/B35",
      },
      {
        image: B36,
        name: "Mecanique",
        count: 7,
        route: "/livres/B36",
      },
      {
        image: B37,
        name: "Ondes",
        count: 8,
        route: "/livres/B37",
      },
      {
        image: B38,
        name: "Optique",
        count: 9,
        route: "/livres/B38",
      },
      {
        image: B39,
        name: "Thermo MPSI",
        count: 10,
        route: "/livres/B39",
      },
      {
        image: B40,
        name: "Thermo MP",
        count: 11,
        route: "/livres/B40",
      },
    ],
  },
  {
    title: "Lotfi",
    description: "Sup/Spe",
    items: [
      {
        image: B41,
        name: "Electromagnetisme",
        count: 1,
        route: "/livres/B41",
      },
      {
        image: B42,
        name: "Mecanique MPSI",
        count: 2,
        route: "/livres/B42",
      },
      {
        image: B43,
        name: "Mecanique MP",
        count: 3,
        route: "/livres/B43",
      },
      {
        image: B44,
        name: "Optique Ondulatoire",
        count: 4,
        route: "/livres/B44",
      },
      {
        image: B45,
        name: "Thermo",
        count: 5,
        route: "/livres/B45",
      },
    ],
  },
  {
    title: "Maxi",
    description: "Sup/Spe",
    items: [
      {
        image: B46,
        name: "Chimie MPSI",
        count: 1,
        route: "/livres/B46",
      },
      {
        image: B47,
        name: "Chimie MP",
        count: 2,
        route: "/livres/B47",
      },
      {
        image: B48,
        name: "Electronique",
        count: 3,
        route: "/livres/B48",
      },
      {
        image: B49,
        name: "Physique",
        count: 4,
        route: "/livres/B49",
      },
    ],
  },
  {
    title: "Methodes et Annales",
    description: "Sup/Spe",
    items: [
      {
        image: B50,
        name: "Chimie MPSI",
        count: 1,
        route: "/livres/B50",
      },
    ],
  },
  {
    title: "Taupe Niveau",
    description: "Spe",
    items: [
      {
        image: B54,
        name: "Optique",
        count: 1,
        route: "/livres/B54",
      },
      {
        image: B55,
        name: "Physique",
        count: 2,
        route: "/livres/B55",
      },
      {
        image: B56,
        name: "Ondes",
        count: 3,
        route: "/livres/B56",
      },
      {
        image: B57,
        name: "Thermodynamique",
        count: 4,
        route: "/livres/B57",
      },
      {
        image: B53,
        name: "Electronique",
        count: 5,
        route: "/livres/B53",
      },
    ],
  },
  {
    title: "Tome",
    description: "Mecanique Quantique",
    items: [
      {
        image: B58,
        name: "Tome 1",
        count: 1,
        route: "/livres/B58",
      },
      {
        image: B59,
        name: "Tome 2",
        count: 2,
        route: "/livres/B59",
      },
      {
        image: B60,
        name: "Tome 3",
        count: 3,
        route: "/livres/B60",
      },
    ],
  },
];
