/*
=========================================================
* e_ React - v2.1.0
=========================================================

* Product Page:
* Copyright 2023 X1-Il (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import Sup from "assets/images/Sup1.jpg";
import Spe from "assets/images/Spe2.jpg";
export default [
  {
    image: Sup,
    name: "Sup",
    route: "/Pbs/Sup/Math",
  },
  {
    image: Spe,
    name: "Spe",
    route: "/Pbs/Spe/Math",
  },
];
