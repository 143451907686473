// @mui icons
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// e_ React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "eCPGE_",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <InstagramIcon />,
      link: "",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/X1-Il",
    },
    {
      icon: <YouTubeIcon />,
      link: "",
    },
  ],
  menus: [
    {
      name: "Cours",
      items: [
        { name: "Math", href: "/Cours/Math" },
        { name: "Physique", href: "/Cours/Physique" },
        { name: "Chimie", href: "/Cours/chimie" },
        { name: "SI", href: "/Cours/SI" },
        { name: "Info", href: "/Cours/Info" },
      ],
    },
    {
      name: "Problèmes",
      items: [
        { name: "Math", href: "/Pbs/Math" },
        { name: "Physique", href: "/Pbs/Physique" },
        { name: "Chimie", href: "/Pbs/Chimie" },
      ],
    },
    {
      name: "Concours",
      items: [
        { name: "CNC ", href: "/CNC" },
        { name: "CCINP", href: "/CCINP" },
        { name: "CCMP", href: "/CCMP" },
        { name: "CCS", href: "/CCS" },
      ],
    },
    {
      name: "About",
      items: [
        { name: "author", href: "/author" },
        { name: "privacy policy", href: "/404" },
        { name: "licenses", href: "/404" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}.<b>Version Juillet</b> Developed by{" "}
      <MKTypography
        component="a"
        href="https://x1-il.netlify.app/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        <b>X1-Il</b>
      </MKTypography>
      .
    </MKTypography>
  ),
};
