import Analyse from "assets/images/Analyse.jpg";
import Algébre from "assets/images/Algebre.jpg";
import Proba from "assets/images/Proba.jpg";
export default [
  {
    image: Analyse,
    name: "Analyse",
    route: "/404",
  },
  {
    image: Algébre,
    name: "Algébre",
    route: "/Pbs/Sup/Math/Algebre",
  },
  {
    image: Proba,
    name: "Proba",
    route: "/404",
  },
];
