import Analyse from "assets/images/Analyse.jpg";
import Algébre from "assets/images/Algebre.jpg";
import Proba from "assets/images/Proba.jpg";
export default [
  {
    image: Analyse,
    name: "Analyse",
    route: "/Pbs/Spe/Math/Analyse",
  },
  {
    image: Algébre,
    name: "Algébre",
    route: "/Pbs/Spe/Math/Algebre",
  },
  {
    image: Proba,
    name: "Proba",
    route: "/Pbs/Spe/Math/Proba",
  },
];
