import Al1 from "assets/images/Formation/Ilham/Al1.png";
import Al2 from "assets/images/Formation/Ilham/Al2.png";
import Al3 from "assets/images/Formation/Ilham/Al3.png";
import Al4 from "assets/images/Formation/Ilham/Al4.png";
import Al5 from "assets/images/Formation/Ilham/Al5.png";
import Al6 from "assets/images/Formation/Ilham/Al6.png";
import Al7 from "assets/images/Formation/Ilham/Al7.png";
import Ag1 from "assets/images/Formation/Ilham/Ag1.png";
import Ag2 from "assets/images/Formation/Ilham/Ag2.png";
import Ag3 from "assets/images/Formation/Ilham/Ag3.png";

export default [
  {
    title: "Thermochimie",
    description: "",
    items: [
      {
        image: Al1,
        name: "Brome",
        count: 1,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: Al2,
        name: "ChuteTartine ReductionNiO DiffusionBarre",
        count: 2,
        route: "/sections/page-sections/features",
      },
      {
        image: Al3,
        name: "FiltrageOptique ChloreEnSolution DetenteEauDiazote",
        count: 3,
      },
      {
        image: Al4,
        name: "Titane",
        count: 4,
      },
      {
        image: Al5,
        name: "Silicium",
        count: 5,
      },
      {
        image: Al6,
        name: "Chrome",
        count: 6,
      },
      {
        image: Al7,
        name: "AvancementGaz Accelerometre TemperatureLune",
        count: 7,
      },
      {
        image: Al1,
        name: "Soufre",
        count: 8,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: Al2,
        name: "Mercure",
        count: 9,
        route: "/sections/page-sections/features",
      },
      {
        image: Al3,
        name: "ConvoyeurRouleaux Monochromateur Vaporeformage",
        count: 10,
      },
      {
        image: Al4,
        name: "Argent",
        count: 11,
      },
    ],
  },
  {
    title: "Diagramme d'Ellingham",
    description: "",
    items: [
      {
        image: Ag1,
        name: "Mercure",
        count: 1,
      },
      {
        image: Ag2,
        name: "Skeleton OndeMetal PreparationTitane",
        count: 2,
      },
      {
        image: Ag3,
        name: "RendementBeauDeRochas Alternateur OxydesCuivre",
        count: 3,
      },
      {
        image: Ag3,
        name: "ModeleThomsonEffetZeeman ChromeEllingham BinaireEauHF",
        count: 4,
      },
    ],
  },
];
