import sup from "assets/images/sup.jpg";
import spe from "assets/images/spe.jpg";
export default [
  {
    image: sup,
    name: "Sup",
    route: "/Cours/Sup/Chimie",
  },
  {
    image: spe,
    name: "Spe",
    route: "/Cours/Spe/Chimie",
  },
];
