import p from "assets/images/p.jpg";
import M from "assets/images/M.jpg";
import SI from "assets/images/SI.jpg";
import C from "assets/images/C.jpg";
import I from "assets/images/I.jpg";
import T from "assets/images/T.jpg";
export default [
  {
    image: p,
    name: "Physique",
    route: "/Cours/Physique",
  },
  {
    image: M,
    name: "Math",
    route: "/Cours/Math",
  },
  {
    image: C,
    name: "Chimie",
    route: "/Cours/Chimie",
  },
  {
    image: SI,
    name: "SI",
    route: "/Cours/SI",
  },
  {
    image: I,
    name: "Informatique",
    route: "/Cours/Info",
  },
  {
    image: T,
    name: "Traduction",
    route: "/Cours/Tr",
  },
];
