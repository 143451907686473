// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";

// e_ React components
import MKBox from "components/MKBox";
// @mui material components
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
// e_ React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import I1 from "assets/images/TIPE/1.png";
import I2 from "assets/images/TIPE/2.png";
import I3 from "assets/images/TIPE/3.png";
import I4 from "assets/images/TIPE/4.png";
import I5 from "assets/images/TIPE/5.png";
import I6 from "assets/images/TIPE/6.png";
import I7 from "assets/images/TIPE/7.png";
import I8 from "assets/images/TIPE/8.png";
import I9 from "assets/images/TIPE/9.png";
import I10 from "assets/images/TIPE/10.png";
import I11 from "assets/images/TIPE/11.png";
import I12 from "assets/images/TIPE/12.png";
import I13 from "assets/images/TIPE/13.png";
import I14 from "assets/images/TIPE/14.png";
import I15 from "assets/images/TIPE/15.png";
import I16 from "assets/images/TIPE/16.png";
import I17 from "assets/images/TIPE/17.png";
import I18 from "assets/images/TIPE/18.png";
import I19 from "assets/images/TIPE/19.png";
import I20 from "assets/images/TIPE/20.png";
import I21 from "assets/images/TIPE/21.png";
import I22 from "assets/images/TIPE/22.png";
import I23 from "assets/images/TIPE/23.png";
import I24 from "assets/images/TIPE/24.png";
import I25 from "assets/images/TIPE/25.png";
import I26 from "assets/images/TIPE/26.png";
import I27 from "assets/images/TIPE/27.png";
import I28 from "assets/images/TIPE/28.png";
import I29 from "assets/images/TIPE/29.png";
import I30 from "assets/images/TIPE/30.png";
import I31 from "assets/images/TIPE/31.png";
import I32 from "assets/images/TIPE/32.png";
import I33 from "assets/images/TIPE/33.png";
import I34 from "assets/images/TIPE/34.png";
import I35 from "assets/images/TIPE/35.png";
import I36 from "assets/images/TIPE/36.png";
import I37 from "assets/images/TIPE/37.png";
import I38 from "assets/images/TIPE/38.png";
import I39 from "assets/images/TIPE/39.png";
import I40 from "assets/images/TIPE/40.png";
import I41 from "assets/images/TIPE/41.png";
import I42 from "assets/images/TIPE/42.png";
import I43 from "assets/images/TIPE/43.png";
import I44 from "assets/images/TIPE/44.png";
import I45 from "assets/images/TIPE/45.png";
import I46 from "assets/images/TIPE/46.png";
import I47 from "assets/images/TIPE/47.png";
import I48 from "assets/images/TIPE/48.png";
import I49 from "assets/images/TIPE/49.png";
import I50 from "assets/images/TIPE/50.png";
import I51 from "assets/images/TIPE/51.png";
import I52 from "assets/images/TIPE/52.png";
import I53 from "assets/images/TIPE/53.png";
import I54 from "assets/images/TIPE/54.png";
import I55 from "assets/images/TIPE/55.png";
import I56 from "assets/images/TIPE/56.png";
import I57 from "assets/images/TIPE/57.png";
import I58 from "assets/images/TIPE/58.png";
import I59 from "assets/images/TIPE/59.png";
import I60 from "assets/images/TIPE/60.png";
import I61 from "assets/images/TIPE/61.png";
import I62 from "assets/images/TIPE/62.png";
import I63 from "assets/images/TIPE/63.png";
import I64 from "assets/images/TIPE/64.png";
import I65 from "assets/images/TIPE/65.png";
import I66 from "assets/images/TIPE/66.png";
import I67 from "assets/images/TIPE/67.png";
import I68 from "assets/images/TIPE/68.png";
import I69 from "assets/images/TIPE/69.png";
import I70 from "assets/images/TIPE/70.png";
import I71 from "assets/images/TIPE/71.png";
import I72 from "assets/images/TIPE/72.png";
import I73 from "assets/images/TIPE/73.png";
import I74 from "assets/images/TIPE/74.png";
import I75 from "assets/images/TIPE/75.png";
import I76 from "assets/images/TIPE/76.png";
const posts = [
  {
    type: "MP",
    name: "Convertion d'energie éolienne",
    image: I1,
    href: "/TIPE/E1",
    // and the rest of the post info
  },
  {
    type: "MP",
    name: "Stockage d'energie éolienne",
    image: I2,
    href: "/TIPE/E2",
    // and the rest of the post info
  },
  {
    type: "MP",
    name: "Propagation d'epidémie",
    image: I3,
    href: "/TIPE/E3",
    // and the rest of the post info
  },
  {
    type: "MP",
    name: "Stéganographie",
    image: I4,
    href: "/TIPE/E4",
    // and the rest of the post info
  },
  {
    type: "MP",
    name: "Voiture autonome",
    image: I5,
    href: "/TIPE/E5",
    // and the rest of the post info
  },
  {
    type: "MP",
    name: "Covid 19",
    image: I6,
    href: "/TIPE/E6",
    // and the rest of the post info
  },
  {
    type: "MP",
    name: "Cryptographie",
    image: I7,
    href: "/TIPE/E7",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Covide 19",
    image: I8,
    href: "/TIPE/E8",
    // and the rest of the post info
  },
  {
    type: "",
    name: "L'energie vibratoire",
    image: I9,
    href: "/TIPE/E9",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Centrales marémotrices",
    image: I10,
    href: "/TIPE/E10",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Covid 19",
    image: I11,
    href: "/TIPE/E11",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Isolation Thermique",
    image: I12,
    href: "/TIPE/E12",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Covid 19",
    image: I13,
    href: "/TIPE/E13",
    // and the rest of the post info
  },
  {
    type: "",
    name: "L'énergie géothermique",
    image: I14,
    href: "/TIPE/E14",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Système électoraux",
    image: I15,
    href: "/TIPE/E15",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Analyse Vibratoire",
    image: I16,
    href: "/TIPE/E16",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Mvt du foule",
    image: I17,
    href: "/TIPE/E17",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Systeme tuned",
    image: I18,
    href: "/TIPE/E18",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Drone",
    image: I19,
    href: "/TIPE/E19",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Soleil Artificiel",
    image: I20,
    href: "/TIPE/E20",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Dessalement de l'eau",
    image: I21,
    href: "/TIPE/E21",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Induction",
    image: I22,
    href: "/TIPE/E22",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Serre Agricole",
    image: I23,
    href: "/TIPE/E23",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Files d'attente",
    image: I24,
    href: "/TIPE/E24",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Digues Maritimes",
    image: I25,
    href: "/TIPE/E25",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Cryptographie",
    image: I26,
    href: "/TIPE/E26",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Thermoelectrique",
    image: I27,
    href: "/TIPE/E27",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Rupture aile d'avion",
    image: I28,
    href: "/TIPE/E28",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Cryptographie",
    image: I29,
    href: "/TIPE/E29",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Systeme Antivol",
    image: I30,
    href: "/TIPE/E30",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Covid 19",
    image: I31,
    href: "/TIPE/E31",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Steganographie",
    image: I32,
    href: "/TIPE/E32",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Energie éolienne",
    image: I33,
    href: "/TIPE/E33",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Energie photovoltaïque",
    image: I34,
    href: "/TIPE/E34",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Energie photovoltaïque",
    image: I35,
    href: "/TIPE/E35",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Drone Thermique",
    image: I36,
    href: "/TIPE/E36",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Les Barrages",
    image: I37,
    href: "/TIPE/E37",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Compression des images",
    image: I38,
    href: "/TIPE/E38",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Drone",
    image: I39,
    href: "/TIPE/E39",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Covid 19",
    image: I40,
    href: "/TIPE/E40",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Tsunami",
    image: I41,
    href: "/TIPE/E41",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Covid 19",
    image: I42,
    href: "/TIPE/E42",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Tensiometre",
    image: I43,
    href: "/TIPE/E43",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Antibiorésistance",
    image: I44,
    href: "/TIPE/E44",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Compression des Signaux",
    image: I45,
    href: "/TIPE/E45",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Electrofiltre",
    image: I46,
    href: "/TIPE/E46",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Désinfection hygiénique",
    image: I47,
    href: "/TIPE/E47",
    // and the rest of the post info
  },
  {
    type: "",
    name: "glycémie humaine",
    image: I48,
    href: "/TIPE/E48",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Fractionnement du sang",
    image: I49,
    href: "/TIPE/E49",
    // and the rest of the post info
  },
  {
    type: "",
    name: "L’écoulement sanguin",
    image: I50,
    href: "/TIPE/E50",
    // and the rest of the post info
  },
  {
    type: "",
    name: "ML en medcine",
    image: I51,
    href: "/TIPE/E51",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Les Files d'attentes",
    image: I52,
    href: "/TIPE/E52",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Production de l'oxygene",
    image: I53,
    href: "/TIPE/E53",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Contrôle Non Destructif",
    image: I54,
    href: "/TIPE/E54",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Covid 19",
    image: I55,
    href: "/TIPE/E55",
    // and the rest of the post info
  },
  {
    type: "",
    name: "MESURE DE LA TEMPERATURE",
    image: I56,
    href: "/TIPE/E56",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Radiotherapie",
    image: I57,
    href: "/TIPE/E57",
    // and the rest of the post info
  },
  {
    type: "",
    name: "DME",
    image: I58,
    href: "/TIPE/E58",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Antibiorésistance",
    image: I59,
    href: "/TIPE/E59",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Parkinson",
    image: I60,
    href: "/TIPE/E60",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Flux sanguin",
    image: I61,
    href: "/TIPE/E61",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Covid 19",
    image: I62,
    href: "/TIPE/E62",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Mvt des foules",
    image: I63,
    href: "/TIPE/E63",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Vaccin",
    image: I64,
    href: "/TIPE/E64",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Electrofiltre",
    image: I65,
    href: "/TIPE/E65",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Tremblements Physiologiques",
    image: I66,
    href: "/TIPE/E66",
    // and the rest of the post info
  },
  {
    type: "",
    name: "prévision des Cardiopathies",
    image: I67,
    href: "/TIPE/E67",
    // and the rest of the post info
  },
  {
    type: "",
    name: "CONSTRUCTION PARASISMIQUE",
    image: I68,
    href: "/TIPE/E68",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Détection de l’anxiété",
    image: I69,
    href: "/TIPE/E69",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Flux sanguin",
    image: I70,
    href: "/TIPE/E70",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Flux sanguin",
    image: I71,
    href: "/TIPE/E71",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Traitement de l’eau potable",
    image: I72,
    href: "/TIPE/E72",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Covid 19",
    image: I73,
    href: "/TIPE/E73",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Velocimetrie",
    image: I74,
    href: "/TIPE/E74",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Diffusion de pollen ",
    image: I75,
    href: "/TIPE/E75",
    // and the rest of the post info
  },
  {
    type: "",
    name: "Compteur Micribalance",
    image: I76,
    href: "/TIPE/E76",
    // and the rest of the post info
  },
  // more posts here
];

function Places() {
  const [searched, setSearched] = useState("");

  const handleSearch = (e) => {
    setSearched(e.target.value);
  };

  const filteredPosts = posts.filter(
    (post) => post.type.includes(searched) || post.name.includes(searched)
  );

  return (
    <MKBox component="section" py={2}>
      <Container>
        {/* Search bar */}
        <Box sx={{ display: "flex", justifyContent: "center", gap: "2rem" }}>
          <Box sx={{ display: "flex", gap: "5px" }}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => setSearched("MP")}
              sx={{ color: "grey.500", borderColor: "grey.500" }}
            >
              MP
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={() => setSearched("PSI")}
              sx={{ color: "grey.500", borderColor: "grey.500" }}
            >
              PSI
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={() => setSearched("TSI")}
              sx={{ color: "grey.500", borderColor: "grey.500" }}
            >
              TSI
            </Button>
          </Box>
          <TextField
            id="search-bar"
            label="Search"
            variant="outlined"
            value={searched}
            onChange={handleSearch}
          />
        </Box>
        <br />
      </Container>
      <Container>
        {/* Now map through filteredPosts instead of all posts */}
        <Grid container spacing={3}>
          {filteredPosts.map((post, index) => (
            <Grid key={index} item xs={12} sm={6} md={3}>
              <TransparentBlogCard
                image={post.image}
                title={post.name}
                description={post.description}
                action={{
                  type: "external",
                  href: post.href,
                  color: "#1A73E8",
                  label: "Voir",
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
