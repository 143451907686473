// @mui material components
import Grid from "@mui/material/Grid";
import React from "react";
// e_ React components
import MKBox from "components/MKBox";
// @mui material components
import CardT from "TIPE/IG/sections/Cards.js";

// Images
import T1 from "../../../assets/images/TI1.png";
import T2 from "../../../assets/images/TI2.png";
import T3 from "../../../assets/images/TI4.png";
import { Link } from "react-router-dom";
import MKButton from "../../../components/MKButton";

function Places() {
  return (
    <MKBox component="section" py={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <MKBox mb={1}>
            <CardT
              image={T1}
              name="Preparation"
              position={{ color: "info", label: "TIPE" }}
              description="Comment preparer votre TIPE"
            />
            <Link to="/TIPE/C1">
              <MKButton variant="contained" color="info" style={{ float: "center" }} size="small">
                Fiche
              </MKButton>
            </Link>
          </MKBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <MKBox mb={1}>
            <CardT
              image={T2}
              name="Conseils"
              position={{ color: "info", label: "TIPE" }}
              description="Conseils pour la preparation et la presentation"
            />
            <Link to="/TIPE/C2">
              <MKButton variant="contained" color="info" style={{ float: "center" }} size="small">
                Fiche
              </MKButton>
            </Link>
          </MKBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <MKBox mb={1}>
            <CardT
              image={T3}
              name="TIPE 2023"
              position={{ color: "info", label: "TIPE" }}
              description="Attendus pedagogiques"
            />
            <Link to="/TIPE/C3">
              <MKButton variant="contained" color="info" style={{ float: "center" }} size="small">
                Fiche
              </MKButton>
            </Link>
          </MKBox>
        </Grid>
      </Grid>
    </MKBox>
  );
}

export default Places;
