// @mui material components
import Card from "@mui/material/Card";
// e_ React components
import MKBox from "components/MKBox";

// e_ React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
import Posts from "TIPE/sections/Posts.js";
import DefaultFooter from "examples/Footers/DefaultFooter";
// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpg";
import footerRoutes from "footer.routes";
import { Link } from "react-router-dom";
import MKButton from "../components/MKButton";
import React from "react";
function TIPE() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/Contact",
          label: "Contact us",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <center>
            <Link to="/TIPE/C4">
              <MKButton variant="outlined" color="info">
                Exemples Sujets Jeux,Sports
              </MKButton>
            </Link>
          </center>
          <br />
          <Posts />
        </Card>
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </MKBox>
    </>
  );
}

export default TIPE;
