import Al1 from "assets/images/MSS.png";

export default [
  {
    title: "Algébre générale",
    description: "",
    items: [
      {
        image: Al1,
        name: "Groupe operant",
        count: 1,
        route: "/Pbs/Spe/Math/P1",
      },
      {
        image: Al1,
        name: "Exposant d'un groupe",
        count: 2,
        route: "/Pbs/Spe/Math/P2",
      },
      {
        image: Al1,
        name: "Les entiers de gauss",
        count: 3,
        route: "/Pbs/Spe/Math/P3",
      },
      {
        image: Al1,
        name: "Sous groupes de (R,+)",
        count: 4,
        route: "/Pbs/Spe/Math/P4",
      },
      {
        image: Al1,
        name: "TD",
        count: 5,
        route: "/Pbs/Spe/Math/P5",
      },
    ],
  },
  {
    title: "Reduction",
    description: "",
    items: [
      {
        image: Al1,
        name: "Automorphisme",
        count: 1,
        route: "/Pbs/Spe/Math/P6",
      },
      {
        image: Al1,
        name: "Classiques",
        count: 2,
        route: "/Pbs/Spe/Math/P7",
      },
      {
        image: Al1,
        name: "Dunford",
        count: 3,
        route: "/Pbs/Spe/Math/P8",
      },
      {
        image: Al1,
        name: "Matrice stockastique",
        count: 4,
        route: "/Pbs/Spe/Math/P9",
      },
      {
        image: Al1,
        name: "Noyaux_images",
        count: 5,
        route: "/Pbs/Spe/Math/P10",
      },
      {
        image: Al1,
        name: "Poly_minimal",
        count: 6,
        route: "/Pbs/Spe/Math/P11",
      },
      {
        image: Al1,
        name: "produit de kronecker",
        count: 7,
        route: "/Pbs/Spe/Math/P13",
      },
      {
        image: Al1,
        name: "Rang_1",
        count: 8,
        route: "/Pbs/Spe/Math/P14",
      },
    ],
  },
  {
    title: "Topologie",
    description: "",
    items: [
      {
        image: Al1,
        name: "calcul de distance par la norme de Schur",
        count: 1,
        route: "/Pbs/Spe/Math/P15",
      },
      {
        image: Al1,
        name: "classiques topologie Mn(K)",
        count: 2,
        route: "/Pbs/Spe/Math/P16",
      },
      {
        image: Al1,
        name: "Formes linéaires continues, hyperplans",
        count: 3,
        route: "/Pbs/Spe/Math/P17",
      },
      {
        image: Al1,
        name: "Normes subordonnées",
        count: 4,
        route: "/Pbs/Spe/Math/P18",
      },
      {
        image: Al1,
        name: "Suites définis par itération, point fixe",
        count: 5,
        route: "/Pbs/Spe/Math/P19",
      },
    ],
  },
];
