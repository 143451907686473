import Pr1 from "assets/images/MSS.png";
export default [
  {
    title: "Probabilités",
    description: "",
    items: [
      {
        image: Pr1,
        name: "Fon_Gen_Mom",
        count: 1,
        route: "/Pbs/Spe/Math/P40",
      },
      {
        image: Pr1,
        name: "Stone_Weiestrass",
        count: 2,
        route: "/Pbs/Spe/Math/P41",
      },
      {
        image: Pr1,
        name: "Proba_khi_deux",
        count: 3,
        route: "/Pbs/Spe/Math/P42",
      },
      {
        image: Pr1,
        name: "Formule_de_Stirling",
        count: 4,
        route: "/Pbs/Spe/Math/P43",
      },
      {
        image: Pr1,
        name: "Loi_de_Zipf",
        count: 5,
        route: "/Pbs/Spe/Math/P44",
      },
      {
        image: Pr1,
        name: "Var_Sous_Gau",
        count: 6,
        route: "/Pbs/Spe/Math/P45",
      },
      {
        image: Pr1,
        name: "Proba_geo_exp_conv",
        count: 7,
        route: "/Pbs/Spe/Math/P46",
      },
      {
        image: Pr1,
        name: "Proba_stone_weierst",
        count: 8,
        route: "/Pbs/Spe/Math/P47",
      },
      {
        image: Pr1,
        name: "Stone_Weiestrass",
        count: 9,
        route: "/Pbs/Spe/Math/P48",
      },
      {
        image: Pr1,
        name: "Proba_fonc_gen_moments",
        count: 10,
        route: "/Pbs/Spe/Math/P49",
      },
      {
        image: Pr1,
        name: "Proba_For_stirling",
        count: 11,
        route: "/Pbs/Spe/Math/P50",
      },
      {
        image: Pr1,
        name: "Proba_Fonc_caract",
        count: 12,
        route: "/Pbs/Spe/Math/P51",
      },
      {
        image: Pr1,
        name: "Proba_convergence",
        count: 13,
        route: "/Pbs/Spe/Math/P52",
      },
      {
        image: Pr1,
        name: "Proba_var_sans_memoire",
        count: 14,
        route: "/Pbs/Spe/Math/P53",
      },
      {
        image: Pr1,
        name: "Proba_min_max_quotient",
        count: 15,
        route: "/Pbs/Spe/Math/P54",
      },
    ],
  },
];
