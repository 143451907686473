import sup from "assets/images/sup.jpg";
import spe from "assets/images/spe.jpg";
export default [
  {
    image: sup,
    name: "Sup",
    route: "/Cours/Tr/Sup",
  },
  {
    image: spe,
    name: "Spe",
    route: "/Cours/Tr/Spe",
  },
];
