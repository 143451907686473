/*
=========================================================
* e_ React - v2.1.0
=========================================================

* Product Page: 
* Copyright 2023 X1-Il (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import A1 from "assets/images/mathematics.jpg";
export default [
  {
    title: "Series Numerique",
    description: "",
    items: [
      {
        image: A1,
        name: "Serie de Stirling",
        count: 1,
        route: "/Pbs/Spe/Math/P20",
      },
    ],
  },
  {
    title: "Sommabilité",
    description: "",
    items: [
      {
        image: A1,
        name: "classiques sommabilité",
        count: 1,
        route: "/Pbs/Spe/Math/P21",
      },
    ],
  },
  {
    title: "Dérivation-intégration",
    description: "",
    items: [
      {
        image: A1,
        name: "Intégrale de Gauss",
        count: 1,
        route: "/Pbs/Spe/Math/P22",
      },
      {
        image: A1,
        name: "Les polynômes de Bernoulli",
        count: 2,
        route: "/Pbs/Spe/Math/P23",
      },
    ],
  },
  {
    title: "Suites et séries de fnct",
    items: [
      {
        image: A1,
        name: "comparaison des modes de cv",
        count: 1,
        route: "/Pbs/Spe/Math/P24",
      },
      {
        image: A1,
        name: "étude d_une série 1",
        count: 2,
        route: "/Pbs/Spe/Math/P33",
      },
      {
        image: A1,
        name: "étude d_une série 2",
        count: 3,
        route: "/Pbs/Spe/Math/P32",
      },
      {
        image: A1,
        name: "étude d_une série 3",
        count: 4,
        route: "/Pbs/Spe/Math/P31",
      },
      {
        image: A1,
        name: "Fonction Digamma",
        count: 5,
        route: "/Pbs/Spe/Math/P25",
      },
      {
        image: A1,
        name: "Fonctions d'Euler",
        count: 6,
        route: "/Pbs/Spe/Math/P26",
      },
      {
        image: A1,
        name: "Les fonctions Eulériennes",
        count: 7,
        route: "/Pbs/Spe/Math/P27",
      },
      {
        image: A1,
        name: "Théorème d'Abel",
        count: 8,
        route: "/Pbs/Spe/Math/P28",
      },
      {
        image: A1,
        name: "Théorème de Stone Weirstrass",
        count: 9,
        route: "/Pbs/Spe/Math/P29",
      },
      {
        image: A1,
        name: "Zéta et zéta alternée de Riemann",
        count: 10,
        route: "/Pbs/Spe/Math/P30",
      },
    ],
  },
  {
    title: "Séries entiers",
    description: "",
    items: [
      {
        image: A1,
        name: "Autour les séries entières",
        count: 1,
        route: "/Pbs/Spe/Math/P34",
      },
      {
        image: A1,
        name: "Nombre de dérangement",
        count: 2,
        route: "/Pbs/Spe/Math/P35",
      },
      {
        image: A1,
        name: "Théorèmes d'Abel et Tauberien faible",
        count: 3,
        route: "/Pbs/Spe/Math/P36",
      },
    ],
  },
  {
    title: "Equa-Diff",
    description: "",
    items: [
      {
        image: A1,
        name: "Cal-diff-Equation-chaleur",
        count: 1,
        route: "/Pbs/Spe/Math/P37",
      },
      {
        image: A1,
        name: "Fonctions holomorphes",
        count: 2,
        route: "/Pbs/Spe/Math/P38",
      },
      {
        image: A1,
        name: "Fonctions holomorphes",
        count: 3,
        route: "/Pbs/Spe/Math/P39",
      },
    ],
  },
];
