import A from "assets/images/MS.png";
export default [
  {
    title: "Polynomes",
    description: "",
    items: [
      {
        image: A,
        name: "Bezout",
        count: 1,
        route: "/Pbs/Sup/Math/P1",
      },
      {
        image: A,
        name: "Hilbert",
        count: 2,
        route: "/Pbs/Sup/Math/P2",
      },
      {
        image: A,
        name: "Legendre",
        count: 3,
        route: "/Pbs/Sup/Math/P3",
      },
      {
        image: A,
        name: "Tchebychev",
        count: 4,
        route: "/Pbs/Sup/Math/P4",
      },
      {
        image: A,
        name: "Polynomes irreductibles dans Q",
        count: 5,
        route: "/Pbs/Sup/Math/P5",
      },
      {
        image: A,
        name: "Théorème de Tchébycheff",
        count: 6,
        route: "/Pbs/Sup/Math/P6",
      },
      {
        image: A,
        name: "Theoreme de Wedderburn",
        count: 7,
        route: "/Pbs/Sup/Math/P7",
      },
    ],
  },
  {
    title: "Déterminant",
    description: "",
    items: [
      {
        image: A,
        name: "Calcul de determinant",
        count: 1,
        route: "/Pbs/Sup/Math/P8",
      },
      {
        image: A,
        name: "Det d'une matrice dependant d'un parametre",
        count: 2,
        route: "/Pbs/Sup/Math/P9",
      },
      {
        image: A,
        name: "Det de vandermonde et de cauchy",
        count: 3,
        route: "/Pbs/Sup/Math/P10",
      },
      {
        image: A,
        name: "Systeme tridiagonal symetrique",
        count: 4,
        route: "/Pbs/Sup/Math/P11",
      },
    ],
  },
  {
    title: "Matrices",
    description: "",
    items: [
      {
        image: A,
        name: "Equation matricielle",
        count: 1,
        route: "/Pbs/Sup/Math/P12",
      },
      {
        image: A,
        name: "Hyperplans de Mn(K)",
        count: 2,
        route: "/Pbs/Sup/Math/P13",
      },
      {
        image: A,
        name: "Matrice productive",
        count: 3,
        route: "/Pbs/Sup/Math/P14",
      },
      {
        image: A,
        name: "Matrices magiques d'ordre 3",
        count: 4,
        route: "/Pbs/Sup/Math/P15",
      },
      {
        image: A,
        name: "Matrices semblables è son inverse",
        count: 5,
        route: "/Pbs/Sup/Math/P16",
      },
      {
        image: A,
        name: "Puissance d'une matrice",
        count: 6,
        route: "/Pbs/Sup/Math/P17",
      },
    ],
  },
  {
    title: "Algébre linéaire",
    description: "",
    items: [
      {
        image: A,
        name: "TD révision",
        count: 1,
        route: "/Pbs/Sup/Math/P18",
      },
      {
        image: A,
        name: "Livre Mohamed HOUIMDI",
        count: 2,
        route: "/Pbs/Sup/Math/P19",
      },
    ],
  },
];
