import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Typography from "@mui/material/Typography";

function CardT({ name, position, description, boxText }) {
  return (
    <Card sx={{ mt: 3 }}>
      <Grid container>
        <Grid item xs={12} md={6} lg={4} sx={{ mt: -6 }}>
          <MKBox width="100%" pt={2} pb={1} px={2}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              height={165} //you can set the box height based on your needs
              bgcolor="#1A73E8"
              borderRadius="0.75rem"
            >
              <Typography variant="h3" color="#fff">
                {boxText}
              </Typography>
            </Box>
          </MKBox>
        </Grid>
        <Grid item xs={12} md={6} lg={8} sx={{ my: "auto" }}>
          <MKBox pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
            <MKTypography variant="h5">{name}</MKTypography>
            <MKTypography variant="h6" color={position.color} mb={1}>
              {position.label}
            </MKTypography>
            <MKTypography variant="body2" color="text">
              {description}
            </MKTypography>
          </MKBox>
        </Grid>
      </Grid>
    </Card>
  );
}

CardT.propTypes = {
  name: PropTypes.string.isRequired,
  boxText: PropTypes.string.isRequired,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string.isRequired,
};

export default CardT;
